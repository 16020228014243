<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { provide, watch } from 'vue'

import SignupInitClassicUser from '~/components/band/signup/init/classicUser.vue'
import SignupInitSelectMethod from '~/components/band/signup/init/SelectMethod.vue'
import SignupInitSoundCloudUser from '~/components/band/signup/init/SoundCloudUser.vue'
import Modal from '~/components/shared/modal.vue'
import { Sm400Gray, Sm600Orange } from '~/components/typography'

import { useSyncReferralCode } from '~/composables/useSyncReferralCode'
import { useUtmTracking } from '~/composables/useUtmTracking'

import { useBandSignupStore } from '~/stores/bandSignup'
import { useLoginStore } from '~/stores/login'
import { useMiscBandSignupStore } from '~/stores/miscBandSignup'
import { useMiscBandSignupReferralStore } from '~/stores/miscBandSignupReferral'
import { useMiscResizeStore } from '~/stores/miscResize'
import { useUserStore } from '~/stores/user'

import { trackEvent } from '~/helpers/LegacyTrackEvent'

import { noFloatInjectKey } from '~/inject-keys/NoFloat'

import type { ComponentKeys } from '~/components/band/signup/init/SelectMethod.vue'
import type { ProfileType } from '~/types/Auth'

type AvailableSignupMethodKeys = `SignupInit${ComponentKeys}`

type AvailableSignupMethodComponents =
  | typeof SignupInitClassicUser
  | typeof SignupInitSoundCloudUser

type Props = {
  noFloat?: boolean
  influencerEntity?: string
  agencyEntity?: string
  referrerAgencyId?: number
  isStandAlone?: boolean
  methodSelectHasCompactDisplay?: boolean
}
type Emits = {
  'signup-method-selected': [isSelected: boolean]
}

const emit = defineEmits<Emits>()
const props = withDefaults(defineProps<Props>(), {
  noFloat: false,
  isStandAlone: false,
  methodSelectHasCompactDisplay: false,
  influencerEntity: undefined,
  agencyEntity: undefined,
  referrerAgencyId: undefined,
})

const route = useRoute()
const localePath = useLocalePath()
const { utmUserTracking } = useUtmTracking()
const { syncReferralCode } = useSyncReferralCode()

provide(noFloatInjectKey, props.noFloat)

const availableSignupMethods = {
  SignupInitClassicUser,
  SignupInitSoundCloudUser,
} as Readonly<
  Record<AvailableSignupMethodKeys, AvailableSignupMethodComponents>
>

const signupMethod = ref<null | ComponentKeys>(null)
watch(signupMethod, () => {
  if (!import.meta.client) return

  const heightTillTopOfContainer = 80
  const el = window.document.getElementById('signupScrollAnchor')
  const config = {
    left: 0,
    behavior: 'smooth',
  } as const

  emit('signup-method-selected', !!signupMethod)
  if (!props.noFloat) {
    el?.scrollTo({
      ...config,
      top: 0,
    })
  } else {
    window.scrollTo({
      ...config,
      top: (el?.offsetTop ?? 0) - (heightTillTopOfContainer + 24),
    })
  }
})

const bandSignupStore = useBandSignupStore()
const { SET_DISPLAY: BAND_SIGNUP_SET_DISPLAY, HIDE: HIDE_SELF } =
  bandSignupStore
const { display: BAND_SIGNUP_IS_DISPLAYED } = storeToRefs(bandSignupStore)

const { DISPLAY: DISPLAY_LOGIN } = useLoginStore()
const { LOAD: LOAD_USER, RESET: RESET_USER } = useUserStore()
const { SCREEN_WIDTH } = storeToRefs(useMiscResizeStore())
const { SET_TRANSITIONING_STATUS: SET_IS_TRANSITIONING_TO_SIGNUP } =
  useMiscBandSignupStore()

const selectSignupMethodComponent =
  computed<null | AvailableSignupMethodComponents>(() => {
    if (!signupMethod.value) return null
    else
      return availableSignupMethods[`SignupInit${signupMethod.value}` as const]
  })
const shouldDisplayBandSignup = computed<boolean>(
  () => props.isStandAlone || BAND_SIGNUP_IS_DISPLAYED.value,
)
watch(shouldDisplayBandSignup, (value) => {
  if (!value) cancelMethodChoice()
})

function cancelMethodChoice(): void {
  signupMethod.value = null
}

async function proceedWithLogin(type: ProfileType | null): Promise<void> {
  HIDE_SELF()
  RESET_USER()
  await LOAD_USER()

  await navigateTo(
    localePath({
      path: type === 'curator' ? '/influencer/dashboard/' : '/band/homepage/',
    }),
  )
}

function goToLogin(): void {
  HIDE_SELF()
  DISPLAY_LOGIN()
  trackEvent(
    {
      category: 'Band Signup',
      action: 'Go to Login',
      signupMethod: signupMethod.value || '',
    },
    route,
  )
}

async function goToSignup(): Promise<void> {
  SET_IS_TRANSITIONING_TO_SIGNUP(true)
  await LOAD_USER()

  if (props.noFloat) {
    // For now noFloat is only used for the referral redirection flow
    // And we really don't plan on changing that any time soon
    // So yeah, this check feels lossy but I'll argue it's aight.
    // Make sure we have a backup of the referral context
    await syncReferralCode()
    trackEvent(
      {
        category: 'Band Signup',
        action: 'Transfer From Widget Signup',
        targetKind: props.influencerEntity ? 'influencer' : 'band',
      },
      route,
    )
  }
  await utmUserTracking().catch()
  await navigateTo(
    localePath({
      path: '/band/signup/',
      query: {
        ...route.query,
      },
    }),
  )
}
</script>

<template>
  <Modal
    :model-value="shouldDisplayBandSignup"
    :title="
      $t(
        SCREEN_WIDTH < 480
          ? 'band.signup.init.text.titleSmall'
          : 'band.signup.init.text.title',
      )
    "
    :no-float="noFloat"
    :has-close="!noFloat"
    :has-cancel="!!signupMethod"
    content-id="signupScrollAnchor"
    @update:model-value="BAND_SIGNUP_SET_DISPLAY(false)"
    @cancel="cancelMethodChoice"
  >
    <div class="tw-pb-6">
      <transition name="fade" mode="out-in">
        <KeepAlive>
          <SignupInitSelectMethod
            v-if="!signupMethod"
            key="selector"
            v-model="signupMethod"
            :compact-display="methodSelectHasCompactDisplay"
            :influencer-entity="influencerEntity"
            :agency-entity="agencyEntity"
            @signup-success="goToSignup"
            @proceed-with-login="proceedWithLogin"
          />
          <component
            :is="selectSignupMethodComponent"
            v-else
            :key="signupMethod"
            :influencer-entity="influencerEntity"
            :referrer-agency-id="referrerAgencyId"
            @signup-success="goToSignup"
            @proceed-with-login="proceedWithLogin"
          />
        </KeepAlive>
      </transition>
      <div
        class="tw-mt-6 tw-cursor-pointer tw-text-center sm:tw-mb-0"
        :class="{ 'tw-mb-20': !!signupMethod && !noFloat }"
        @click="goToLogin"
      >
        <Sm400Gray class="tw-mb-1 tw-block 375:tw-mb-0 375:tw-inline">{{
          $t('band.signup.init.text.signin')
        }}</Sm400Gray>
        <Sm600Orange class="tw-block 375:tw-inline">{{
          $t('band.signup.init.button.signin')
        }}</Sm600Orange>
      </div>
    </div>
  </Modal>
</template>
