<script setup lang="ts">
import { storeToRefs } from 'pinia'

import HeightExpand from '~/components/transitions/heightExpand.vue'
import { BtnFacebook, BtnOrange } from '~/components/ui/Buttons/'
import ButtonGoogle from '~/components/ui/Buttons/Google.vue'
import ButtonSoundcloud from '~/components/ui/Buttons/Soundcloud.vue'
import VText from '~/components/ui/VText.vue'

import { capitalize } from '~/mixins/global'

import { useStartSso } from '~/composables/Auth/useStartSso'
import { useSegmentIdentify } from '~/composables/Segment/useSegmentIdentify'
import { useSegmentTrack } from '~/composables/Segment/useSegmentTrack'
import { useCustomerIO } from '~/composables/useCustomerIO'
import { useGetMediaUrl } from '~/composables/useGetMediaUrl'
import { getLocaleFromLanguage, useLanguage } from '~/composables/useLanguage'
import { useProvideAuthFetch } from '~/composables/useProvideAuthFetch'
import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import { useMiscBandSignupStore } from '~/stores/miscBandSignup'
import { useUserStore } from '~/stores/user'

import { isFeatureOnForGroup } from '~/helpers/GrowthBookHelpers'
import { trackEvent } from '~/helpers/LegacyTrackEvent'

import {
  provideAuthApiSsoRegister,
  registerCallFailed,
} from '~/api-auth/Register'
import {
  provideLoginSession,
  provideLogoutSession,
} from '~/api-core/Login/Session'

import type { RegisterUserSuccess } from '~/api-auth/Register'
import type { SsoProvider } from '~/types/Auth'

export type ComponentKeys = 'ClassicUser' | 'SoundCloudUser'

const { authStartSso } = useStartSso()
const route = useRoute()
const { $growthBook } = useNuxtApp()

type Props = {
  compactDisplay: boolean
  modelValue: string | null
  influencerEntity?: string
  agencyEntity?: string
  referrerAgencyId?: number
}
type Emits = {
  'update:modelValue': [key: ComponentKeys]
  'proceed-with-login': [
    profileType: RegisterUserSuccess['user']['profile_type'],
  ]
  'signup-success': []
}

const props = withDefaults(defineProps<Props>(), {
  compactDisplay: false,
})
const emit = defineEmits<Emits>()

const { t } = useI18n()
const { coreFetch } = useProvideCoreFetch()
const { authFetch } = useProvideAuthFetch()
const { getCurrentLanguage } = useLanguage()
const { getMediaUrl } = useGetMediaUrl()
const segmentIdentify = useSegmentIdentify()
const segmentTrack = useSegmentTrack()
const { customerIOIdentify } = useCustomerIO()

const userStore = useUserStore()
const { soundcloud_code: USER_SOUNDCLOUD_STORE } = storeToRefs(userStore)
const { IS_INF: USER_IS_CURATOR } = userStore

const { SET_SOUNDCLOUD_SIGNUP_STATUS } = useMiscBandSignupStore()

const displayUserExistsError = ref(false)
const displayAuthv2FailureError = ref(false)
const v2AuthInterval = ref<number | null>(null)

onBeforeUnmount(() => {
  if (v2AuthInterval.value) clearInterval(v2AuthInterval.value)
})

onMounted(() => {
  if (USER_SOUNDCLOUD_STORE.value) emit('update:modelValue', 'SoundCloudUser')
})

const bulletPointKeys = computed(() => {
  if (USER_IS_CURATOR) return ['youveHelpedAritsts', 'saveMoney']

  if (props.influencerEntity) return ['contactInf', 'bigAccess', 'cheapAf']

  if (props.agencyEntity) {
    return [
      'agencyFastAccess',
      'agencyGuaranteedReturns',
      'agencyAdaptedForAll',
      'agencyBonus',
    ]
  }

  return ['fastAccess', 'guaranteedReturns', 'adaptedForAll']
})

const checkKeys = computed<string[]>(() => {
  return bulletPointKeys.value.map((key) =>
    t(`band.signup.init.selectMethod.bulletPoints.${key}`, {
      influencerEntity: props.influencerEntity,
      agencyEntity: props.agencyEntity,
    }),
  )
})

// Start AB Test 'soundcloud-auth-rollout
const isSoundCloudAuthEnabled = computed<boolean>(() =>
  isFeatureOnForGroup(
    $growthBook,
    'soundcloud-auth-rollout',
    'soundcloud-auth-enabled',
  ),
)
// End AB Test 'soundcloud-auth-rollout

function v2GoogleSignup() {
  displayUserExistsError.value = false
  displayAuthv2FailureError.value = false
  authStartSso('google', (code) => {
    signupWithSsoCallback('google', code)
  })
}

async function signupWithSsoCallback(
  provider: Exclude<SsoProvider, 'soundcloud'>,
  code: string,
  emitOnSuccess = true,
) {
  const registerWithSso = provideAuthApiSsoRegister(authFetch)
  const logUserToCore = provideLoginSession(coreFetch)

  const registerResponse = await registerWithSso(provider, {
    code,
    accepted_tos: true,
    lang: getLocaleFromLanguage(getCurrentLanguage()),
  })
  if (registerCallFailed(registerResponse)) {
    displayUserExistsError.value = true
    throw new Error('Failed register')
  }

  await provideLogoutSession(coreFetch)()

  try {
    const user = await logUserToCore(registerResponse.access)
    const userTrackingData = {
      sign_up_method: provider,
      has_come_from_referral: route.path.includes('referral'),
      has_come_from_widget: route.query.utm_source === 'widget',
      has_come_from_curator_dash: USER_IS_CURATOR,
    }

    trackEvent(
      {
        category: 'Band Signup',
        action: `Click on ${capitalize(provider)} Signup`,
        isReferral: route.path.includes('referral'),
      },
      route,
    )
    segmentTrack('Sign Up - Sign Up Finalized', {
      ...userTrackingData,
      landing_page_url: route.path,
      landing_page_accessed_from: window.localStorage.getItem(
        'comesFromCustomSignup',
      )
        ? 'CDBaby'
        : 'Groover',
      referrer_agency_id: props.referrerAgencyId,
      widget_curator_name: props.influencerEntity,
    })
    segmentIdentify({
      ...userTrackingData,
      user_id: user.id,
      date_sign_up: new Date(),
    })
    customerIOIdentify(user.id, {
      email: user.email,
    })

    if (!emitOnSuccess) return registerResponse

    if (registerResponse.user.profile_type)
      emit('proceed-with-login', registerResponse.user.profile_type)
    else emit('signup-success')

    return registerResponse
  } catch (_) {
    displayAuthv2FailureError.value = true
  }
}

function handleFacebookClick(): void {
  trackEvent(
    {
      category: 'Band Signup',
      action: 'Click on Facebook Signup',
      isReferral: route.path.includes('referral'),
    },
    route,
  )
  segmentTrack('Sign Up - Sign Up Method Selected', {
    sign_up_method: 'facebook',
  })

  displayUserExistsError.value = false
  displayAuthv2FailureError.value = false
  authStartSso('facebook', (code) => {
    signupWithSsoCallback('facebook', code)
  })
}

function handleEmailClick(): void {
  displayUserExistsError.value = false
  displayAuthv2FailureError.value = false
  emit('update:modelValue', 'ClassicUser')
  segmentTrack('Sign Up - Sign Up Method Selected', {
    sign_up_method: 'email',
  })
  trackEvent(
    {
      category: 'Band Signup',
      action: 'Click on Email Signup',
      isReferral: route.path.includes('referral'),
    },
    route,
  )
}

function v2SoundcloudSignup() {
  displayUserExistsError.value = false
  displayAuthv2FailureError.value = false
  authStartSso('soundcloud', (code) => {
    SET_SOUNDCLOUD_SIGNUP_STATUS({
      soundcloud_code: code,
      first_name: '',
      soundcloud_token: null,
    })
    return emit('update:modelValue', 'SoundCloudUser')
  })
}
</script>

<template>
  <div
    class="signupInitSelectMethodWrapper tw-grid tw-grid-cols-1 tw-gap-6 md:tw-gap-10"
  >
    <div
      v-if="!compactDisplay"
      class="tw-grid tw-grid-cols-1 tw-gap-6 tw-text-center"
    >
      <VText cfg="sans/24/bold">{{
        USER_IS_CURATOR
          ? t('band.signup.init.selectMethod.title.explainCurator')
          : t('band.signup.init.selectMethod.title.explain')
      }}</VText>
      <div
        v-for="(translatedResult, index) in checkKeys"
        :key="index"
        class="tw-relative tw-text-center"
      >
        <i class="fas fa-check tw-mr-2 tw-text-base tw-text-orange-500" />
        <VText cfg="sans/16/regular">{{ translatedResult }}</VText>
      </div>
      <div v-if="USER_IS_CURATOR" class="tw-relative tw-text-center">
        <i
          class="fa-solid fa-circle-info tw-mr-2 tw-text-base tw-text-orange-500"
        />
        <VText cfg="sans/16/medium">{{
          t('band.signup.init.selectMethod.bulletPoints.curatorInfo')
        }}</VText>
      </div>
    </div>
    <ClientOnly>
      <div
        class="tw-mx-auto tw-grid tw-w-[272px] tw-grid-cols-1 tw-gap-3 sm:tw-w-[369px] md:tw-w-[400px]"
      >
        <ButtonGoogle small @click="v2GoogleSignup">
          <span class="btnText tw-relative tw-block">
            <span
              class="tw-absolute tw-left-0 tw-top-0 tw-flex tw-h-full tw-items-center"
            >
              <img
                alt="google"
                :src="getMediaUrl('googleLogo.svg')"
                class="tw-h-4"
              />
            </span>
            <span>{{ t('band.signup.init.selectMethod.button.google') }}</span>
          </span>
        </ButtonGoogle>
        <BtnFacebook small @click="handleFacebookClick">
          <div class="btnText">
            <div class="facebookIconContainer">
              <i class="fab fa-facebook-square" />
            </div>
            <span>{{
              t('band.signup.init.selectMethod.button.facebook')
            }}</span>
          </div>
        </BtnFacebook>
        <!-- Start AB Test 'soundcloud-auth-rollout' -->
        <ButtonSoundcloud
          v-if="isSoundCloudAuthEnabled"
          small
          @click="v2SoundcloudSignup"
        >
          <img
            class="tw-absolute tw-left-4 tw-h-4 tw-py-[3px]"
            alt=""
            :src="getMediaUrl('soundcloud-logo-cloud-white.svg')"
          />
          <span>{{
            t('band.signup.init.selectMethod.button.soundcloud')
          }}</span>
        </ButtonSoundcloud>
        <BtnOrange
          data-test-id="emailSignupCTA"
          small
          @click="handleEmailClick"
        >
          {{ t('band.signup.init.selectMethod.button.classic') }}
        </BtnOrange>
        <HeightExpand>
          <VText
            v-if="displayUserExistsError"
            cfg="sans/14/medium"
            color="error"
            class="tw-inline-block tw-w-full tw-text-center"
            >{{ t('error.emailAlreadyUsed') }}</VText
          >
        </HeightExpand>
        <HeightExpand>
          <VText
            v-if="displayAuthv2FailureError"
            cfg="sans/14/medium"
            color="error"
            class="tw-inline-block tw-w-full tw-text-center"
            >{{ t('error.02') }}</VText
          >
        </HeightExpand>
      </div>
    </ClientOnly>
  </div>
</template>
<style scoped lang="scss">
.btn {
  @apply tw-w-full;
}

.btnText {
  @apply tw-relative;
}

div.facebookIconContainer {
  @apply tw-absolute tw-left-0 tw-flex tw-h-full tw-items-center tw-justify-start;

  > i {
    @apply tw-text-base tw-text-white;
  }
}
</style>
