<script setup lang="ts">
import { z } from 'zod'

import CreateUserTemplate from '~/components/band/signup/init/createUser/template.vue'
import VTextInput from '~/components/ui/Inputs/VText.vue'
import VText from '~/components/ui/VText.vue'

import { useSegmentIdentify } from '~/composables/Segment/useSegmentIdentify'
import { useSegmentTrack } from '~/composables/Segment/useSegmentTrack'
import { useCustomerIO } from '~/composables/useCustomerIO'
import { useFieldValidation } from '~/composables/useFieldValidation'
import { getLocaleFromLanguage, useLanguage } from '~/composables/useLanguage'
import { useProvideAuthFetch } from '~/composables/useProvideAuthFetch'
import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import { useUserStore } from '~/stores/user'

import { setGrowthBookAttributes } from '~/helpers/GrowthBookHelpers'
import { trackEvent } from '~/helpers/LegacyTrackEvent'

import { provideAuthApiRegister, registerCallFailed } from '~/api-auth/Register'
import {
  provideLoginSession,
  provideLogoutSession,
} from '~/api-core/Login/Session'

import type { AuthUserReturn } from '~/api-core/Account/AuthUser'
import type { ProfileType } from '~/types/Auth'

type Props = {
  influencerEntity?: string
  referrerAgencyId?: number
}
type Emits = {
  'proceed-with-login': [profileType: ProfileType]
  'signup-success': [user: AuthUserReturn]
}

const emit = defineEmits<Emits>()
const props = defineProps<Props>()

const { $growthBook, $sentry } = useNuxtApp()
const { t } = useI18n()
const route = useRoute()
const { coreFetch } = useProvideCoreFetch()
const { authFetch } = useProvideAuthFetch()
const segmentIdentify = useSegmentIdentify()
const segmentTrack = useSegmentTrack()
const { customerIOIdentify } = useCustomerIO()
const { getCurrentLanguage } = useLanguage()
const { path: routePath, query: routeQuery } = route

const loading = ref(false)
const email = ref('')
const firstName = ref('')
const authServiceError = ref<null | string[]>(null)
const displayPasswordAnnotation = ref(false)
const displayPassword = ref(false)

const {
  value: passwordFieldValue,
  errorMessage: passwordFieldErrorMessage,
  validate: validatePasswordField,
  resetField: resetPasswordField,
} = useFieldValidation(
  'password',
  z.string().min(8, t('auth.login.error.passwordMinLength')),
  {
    syncVModel: true,
    initialValue: '',
  },
)

const { IS_INF: USER_IS_CURATOR } = useUserStore()
const inputType = computed<'text' | 'password'>(() =>
  displayPassword.value ? 'text' : 'password',
)

const isPasswordValid = computed(() => {
  return !!(!passwordFieldErrorMessage.value && passwordFieldValue.value.length)
})

onMounted(() => resetPasswordField())

function trackFinalizeEvent(userId: number, userEmail: string) {
  trackEvent(
    {
      category: 'Band Signup',
      action: 'Create User',
      label: 'mail',
      isReferral: routePath.includes('referral'),
    },
    route,
  )

  const userTrackingData = {
    sign_up_method: 'email' as const,
    has_come_from_referral: routePath.includes('referral'),
    has_come_from_widget: routeQuery.utm_source === 'widget',
  }

  customerIOIdentify(userId, {
    email: userEmail,
  })

  // identify with segment
  segmentIdentify({
    ...userTrackingData,
    user_id: userId,
    date_sign_up: new Date(),
  })
  segmentTrack('Sign Up - Sign Up Finalized', {
    ...userTrackingData,
    has_come_from_curator_dash: USER_IS_CURATOR,
    referrer_agency_id: props.referrerAgencyId || undefined,
    widget_curator_name: props.influencerEntity || undefined,
    landing_page_url: routePath,
    landing_page_accessed_from: window.localStorage.getItem(
      'comesFromCustomSignup',
    )
      ? 'CDBaby'
      : 'Groover',
  })
}

async function authV2Signup() {
  const registerUserOnAuth = provideAuthApiRegister(authFetch)
  const logUserToCore = provideLoginSession(coreFetch)

  try {
    const registerResponse = await registerUserOnAuth({
      accepted_tos: true,
      first_name: firstName.value,
      lang: getLocaleFromLanguage(getCurrentLanguage()),
      email: email.value.toLowerCase().trim(),
      password1: passwordFieldValue.value,
      password2: passwordFieldValue.value,
    })

    await provideLogoutSession(coreFetch)()
    const user = await logUserToCore(registerResponse.access)

    return user
  } catch (error) {
    if (!authFetch.$isFetchError(error)) throw error

    const data = error?.data
    if (registerCallFailed(data)) {
      authServiceError.value = data
        ? Object.entries(data).reduce<string[]>((acc, [, errMessages]) => {
            if (errMessages) acc.push(...errMessages)

            return acc
          }, [])
        : null
    }

    throw new Error('Failed register')
  }
}
async function finalizeSignup() {
  if (loading.value) return

  authServiceError.value = null
  try {
    const { valid } = await validatePasswordField()
    if (!valid) return

    loading.value = true

    const newCoreUser = await authV2Signup()

    trackFinalizeEvent(newCoreUser.id, newCoreUser.email)
    setGrowthBookAttributes({ userId: newCoreUser.id }, $growthBook)
    emit('signup-success', newCoreUser)
  } catch (err) {
    $sentry?.captureException(err)
  } finally {
    loading.value = false
  }
}
</script>

<template>
  <div class="tw-relative">
    <CreateUserTemplate
      :first-name="firstName"
      :email="email"
      :disabled="loading"
      @update-email="email = $event"
      @update-first-name="firstName = $event"
      @request-signup="finalizeSignup"
      @request-validation="validatePasswordField()"
    >
      <template #mid>
        <div class="tw-relative">
          <VTextInput
            v-model="passwordFieldValue"
            :p-validity="!passwordFieldErrorMessage"
            left-picto="fas fa-lock"
            hide-right-picto
            :label="$t('band.signup.init.classicUser.input.passwordLabel')"
            :placeholder="
              $t('band.signup.init.classicUser.input.passwordPlaceholder')
            "
            :type="inputType"
            :display-right-check="false"
            :offset="80"
            data-test-id="emailArtistSignupPasswordInput"
            @focus="displayPasswordAnnotation = true"
            @blur="displayPasswordAnnotation = false"
          />
          <transition name="scale" mode="out-in">
            <div
              :key="displayPassword.toString()"
              class="sightIcon"
              @click.stop="displayPassword = !displayPassword"
            >
              <i
                class="fas"
                :class="{
                  'fa-eye-slash': displayPassword,
                  'fa-eye': !displayPassword,
                }"
              />
            </div>
          </transition>
        </div>
        <transition name="fade" mode="out-in">
          <div v-if="passwordFieldErrorMessage" key="error" class="tw-mt-2">
            <i
              v-show="passwordFieldValue.length > 0"
              class="fas fa-times tw-text-error"
            ></i>
            <VText
              v-if="!passwordFieldValue.length"
              cfg="sans/14/medium"
              color="error"
              >{{ $t('errors.input.required_field') }}</VText
            >
            <VText v-else cfg="sans/14/medium" color="gray-500">{{
              $t('errors.input.min_characters', 8)
            }}</VText>
          </div>
          <div
            v-else-if="displayPasswordAnnotation"
            key="focus"
            class="tw-mt-2"
          >
            <div
              :class="{
                'tw-text-green-700': isPasswordValid,
                'tw-text-gray-500': passwordFieldErrorMessage,
              }"
              class="tw-inline-block"
            >
              <i v-if="isPasswordValid" class="fas fa-check"></i>
              <i v-else class="fas fa-times"></i>
            </div>
            <VText
              cfg="sans/14/medium"
              :color="isPasswordValid ? 'green-700' : 'gray-500'"
              >{{ $t('errors.input.min_characters', 8) }}</VText
            >
          </div>
        </transition>
        <transition name="fade" mode="out-in">
          <div
            v-if="authServiceError"
            class="tw-mx-auto tw-pt-4 tw-text-center"
          >
            <VText
              v-for="errorMessage in authServiceError"
              :key="errorMessage"
              cfg="sans/14/medium"
              color="error"
              class="tw-inline-block"
            >
              {{ errorMessage }}
            </VText>
          </div>
        </transition>
      </template>
    </CreateUserTemplate>
  </div>
</template>

<style lang="scss" scoped>
.sightIcon {
  @apply tw-absolute tw-cursor-pointer tw-text-base tw-text-gray-500;

  top: 42px;
  right: 16px;
}
</style>
