<script setup lang="ts">
import { storeToRefs } from 'pinia'

import CreateUserTemplate from '~/components/band/signup/init/createUser/template.vue'
import VText from '~/components/ui/VText.vue'

import { useSegmentIdentify } from '~/composables/Segment/useSegmentIdentify'
import { useSegmentTrack } from '~/composables/Segment/useSegmentTrack'
import { useCustomerIO } from '~/composables/useCustomerIO'
import { getLocaleFromLanguage, useLanguage } from '~/composables/useLanguage'
import { useProvideAuthFetch } from '~/composables/useProvideAuthFetch'
import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import { useMiscBandSignupStore } from '~/stores/miscBandSignup'
import { useUserStore } from '~/stores/user'

import { setGrowthBookAttributes } from '~/helpers/GrowthBookHelpers'

import {
  provideAuthApiSsoRegister,
  registerCallFailed,
} from '~/api-auth/Register'
import { provideLoginSession } from '~/api-core/Login/Session'

import type { ProfileType } from '~/types/Auth'

type Props = {
  influencerEntity?: string
  referrerAgencyId?: number
}
type Emits = {
  'proceed-with-login': [profileType: ProfileType]
  'signup-success': []
}

const emit = defineEmits<Emits>()
const props = defineProps<Props>()

const { coreFetch } = useProvideCoreFetch()
const { authFetch } = useProvideAuthFetch()
const { $growthBook } = useNuxtApp()
const { getCurrentLanguage } = useLanguage()
const { path: routePath, query: routeQuery } = useRoute()
const segmentIdentify = useSegmentIdentify()
const segmentTrack = useSegmentTrack()
const { customerIOIdentify } = useCustomerIO()

const { soundcloudContext: SOUNDCLOUD_SIGNUP_DATA } = storeToRefs(
  useMiscBandSignupStore(),
)
const { IS_INF: USER_IS_CURATOR } = storeToRefs(useUserStore())

const email = ref('')
const firstName = ref(SOUNDCLOUD_SIGNUP_DATA.value.first_name)
const authFailed = ref(false)

async function v2FinalizeSignup() {
  if (!SOUNDCLOUD_SIGNUP_DATA.value.soundcloud_code) return

  const registerWithSso = provideAuthApiSsoRegister(authFetch)
  const logUserToCore = provideLoginSession(coreFetch)

  const registerResponse = await registerWithSso('soundcloud', {
    code: SOUNDCLOUD_SIGNUP_DATA.value.soundcloud_code,
    accepted_tos: true,
    lang: getLocaleFromLanguage(getCurrentLanguage()),
    email: email.value,
  })

  if (registerCallFailed(registerResponse)) {
    authFailed.value = true
    throw new Error('Failed register')
  }

  const user = await logUserToCore(registerResponse.access)

  trackFinalizeEvent(user.id, user.email)
  if (registerResponse.user.profile_type)
    emit('proceed-with-login', registerResponse.user.profile_type)
  else emit('signup-success')
}

function trackFinalizeEvent(userId: number, userEmail: string) {
  const userTrackingData = {
    sign_up_method: 'soundcloud' as const,
    has_come_from_referral: routePath.includes('referral'),
    has_come_from_widget: routeQuery.utm_source === 'widget',
  }

  segmentIdentify({
    ...userTrackingData,
    user_id: userId,
    date_sign_up: new Date(),
  })
  customerIOIdentify(userId, {
    email: userEmail,
  })
  setGrowthBookAttributes({ userId }, $growthBook)
  segmentTrack('Sign Up - Sign Up Finalized', {
    ...userTrackingData,
    has_come_from_curator_dash: USER_IS_CURATOR.value,
    referrer_agency_id: props.referrerAgencyId,
    widget_curator_name: props.influencerEntity,
    landing_page_url: routePath,
    landing_page_accessed_from: window.localStorage.getItem(
      'comesFromCustomSignup',
    )
      ? 'CDBaby'
      : 'Groover',
  })
}
</script>

<template>
  <CreateUserTemplate
    :first-name="firstName"
    :email="email"
    :is-social="true"
    @update-email="email = $event"
    @update-first-name="firstName = $event"
    @request-signup="v2FinalizeSignup"
  >
    <template #mid>
      <VText v-if="authFailed" cfg="sans/14/medium" color="error">{{
        $t('error.emailAlreadyUsed')
      }}</VText>
    </template>
  </CreateUserTemplate>
</template>
